const generalCollectionNotificationStatement = `_This Collection Notification Statement was last revised on the 5th Feburary 2025._

  Stafflink Pty Ltd (ACN 626 030 742) and its Related Bodies Corporate (having the meaning given in Australia's Corporations Act 2001 (Cth)) (Stafflink, we, us or our) is committed to protecting the privacy of individuals and their personal information and complies with the Privacy Act 1988 (Cth) (Privacy Act).

Please read this carefully as it will have important consequences for you in relation to the collection, use and disclosure of your personal information you provide to Stafflink.

You understand that:
1. Stafflink is collecting your personal information and can be contacted by email at privacy@stafflink.com.au, by phone on +61 7 3778 6267, or by post at Level 4, 2 King Street, Bowen Hills, Queensland 4006.

2. Stafflink will collect your personal information directly from you. In certain circumstances, Stafflink may be required to collect personal information from third parties, depending on the nature of your enquiry.

3. The purpose of Stafflink collecting your personal information is:

- (a) to process your enquiry, and provide you with information in relation to your enquiry. This includes without limitation, contacting you regarding any enquiries submitted via the “Send an enquiry” page of the Stafflink website;
- (b) if you are a customer, to provide our services to you;
- (c) to offer you updates on services, events information, or other content or services that may be of interest to you and to provide you with direct marketing communications from us or our related entities and associates (using direct mail, telemarketing, and email);
- (d) to protect Stafflink's website from security threats, fraud or other criminal activities; and
- (e) to use for the administration and management of Stafflink, and you acknowledge and agree to your personal information being collected and used for, and in connection with, these purposes and any other purposes described in our Privacy Policy.

4. If we do not collect this personal information in connection with the purposes in paragraph 3, then Stafflink may be unable to:
- (a) provide you with customer services, process your enquiry or complaint, or supply you with information;
- (b) provide you with our services; or
- (c) send you direct marketing communications (unless you have consented to such communications through your other dealings with Stafflink).

5. Your personal information may be disclosed to the following classes of people and organisations in connection with the purposes in paragraph 3:
- (a) Stafflink its personnel and any related entities;
- (b) third parties in connection with our customer service and enquiry processes;
- (c) third party service providers, including without limitation, ecommerce, payment gateway providers and financial institutions, marketing or IT service providers, contractors and sales consultants, and other parties who assist us in providing our services to you;
- (d) third parties in connection with the sale of all or any part of a business or a company owned by a Stafflink entity;
- (e) any other organisation as expressly covered in our Privacy Policy.

6. It is possible that some of the information collected may be disclosed to Stafflink's related body corporate or service providers outside of Australia. You consent to your information being disclosed to and held at, a destination outside Australia for this purpose, including but not limited to countries in the European Union or the United States, where we utilise third party service providers to assist Stafflink with providing our services to our customers. You understand and acknowledge that Australian Privacy Principle 8.1 will not apply to such disclosures of your personal information.

7. Stafflink's Privacy Policy sets out the process as to how you can access and correct any of your personal information collected under this form as well as to how you can make a complaint if Stafflink has breached the Privacy Act in the handling of your personal information. Our Privacy Policy is available at [Privacy Policy](https://stafflink.com.au/privacy-policy/) - Stafflink Pty Ltd or can be otherwise be obtained by requesting a copy from Stafflink.

By continuing to submit your enquiry via this website, indicates that you have read this collection notification and acknowledge that your personal information will be collected, used and disclosed in accordance with this collection notice and as otherwise detailed in Stafflink's Privacy Policy and, to the extent it is necessary, that you give your voluntary express consent to Stafflink collecting, using, storing, disclosing and disposing of your personal information in this manner.

`
export default generalCollectionNotificationStatement
